//Slider on the main page
$(document).ready(function() {
    $('.slider').slick({
        slidesToShow: 3,
        infinite: true,
        arrows: false,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });
});

//Navbar animation of search input in main navigation
(function() {
    var search_input = $("[data-navbar-search='input']");
    $("[data-navbar-search='link']").on('click', function() {
        search_input.toggleClass('navbar-search__input_show');
    });
})();

//function changeIcon($hover_elem, $img_elem, $modifier = 2) {
//
//    if (!$hover_elem || !$img_elem)
//    {
//        return;
//    }
//
//    var src_base = $img_elem.attr('src');
//
//    var index = src_base.lastIndexOf('.');
//    var begining = src_base.slice(0, index);
//    var ending = src_base.slice(index, src_base.length);
//    var src_hover = begining + "-" + $modifier + ending;
//
//    $hover_elem.on('mouseover', function() {
//        $img_elem.attr('src', src_hover);
//    });
//
//    $hover_elem.on('mouseout', function() {
//        $img_elem.attr('src', src_base);
//    });
//}
// 
//changeIcon($('.contact-button'), $('.contact-button__icon'));


(function($) {
    
    jQuery.scrollSpeed = function(step, speed, easing) {
        
        var $document = $(document),
            $window = $(window),
            $body = $('html, body'),
            option = easing || 'default',
            root = 0,
            scroll = false,
            scrollY,
            scrollX,
            view;
            
        if (window.navigator.msPointerEnabled)
        
            return false;
            
        $window.on('mousewheel DOMMouseScroll', function(e) {
            
            var deltaY = e.originalEvent.wheelDeltaY,
                detail = e.originalEvent.detail;
                scrollY = $document.height() > $window.height();
                scrollX = $document.width() > $window.width();
                scroll = true;
            
            if (scrollY) {
                
                view = $window.height();
                    
                if (deltaY < 0 || detail > 0)
            
                    root = (root + view) >= $document.height() ? root : root += step;
                
                if (deltaY > 0 || detail < 0)
            
                    root = root <= 0 ? 0 : root -= step;
                
                $body.stop().animate({
            
                    scrollTop: root
                
                }, speed, option, function() {
            
                    scroll = false;
                
                });
            }
            
            if (scrollX) {
                
                view = $window.width();
                    
                if (deltaY < 0 || detail > 0)
            
                    root = (root + view) >= $document.width() ? root : root += step;
                
                if (deltaY > 0 || detail < 0)
            
                    root = root <= 0 ? 0 : root -= step;
                
                $body.stop().animate({
            
                    scrollLeft: root
                
                }, speed, option, function() {
            
                    scroll = false;
                
                });
            }
            
            return false;
            
        }).on('scroll', function() {
            
            if (scrollY && !scroll) root = $window.scrollTop();
            if (scrollX && !scroll) root = $window.scrollLeft();
            
        }).on('resize', function() {
            
            if (scrollY && !scroll) view = $window.height();
            if (scrollX && !scroll) view = $window.width();
            
        });       
    };
    
    jQuery.easing.default = function (x,t,b,c,d) {
    
        return -c * ((t=t/d-1)*t*t*t - 1) + b;
    };
    
})(jQuery);

jQuery.scrollSpeed(100, 1200);  
